import includes from 'lodash.includes';
import { format } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';

/*
const offsetByTimeZone = (date: Date | string) => {
  if (typeof date === 'string' && includes(date, 'Z')) {
    date = new Date(date);
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    return formatInTimeZone(date, timeZone, 'yyyy-MM-dd HH:mm:ss');
  }

  return format(date, 'yyyy-MM-dd HH:mm:ss');
}; */

const formatDate = (date: Date | string, formatter = 'dd.MM.yyyy') => {
  // const offsetDate = offsetByTimeZone(date);

  return format(new Date(date), formatter);
};

const formatTime = (date: Date | string, formatter = 'HH.mm') => formatDate(date, formatter);
const formatDateTime = (date: Date | string, formatter = 'dd.MM.yyyy HH.mm') => formatDate(date, formatter);

export default {
  formatDate,
  formatTime,
  formatDateTime,
};
