import React from 'react';
import { Box, Typography } from '@mui/material';

import translate from '@localise/translate';

import PageWrap from '@layout/PageWrap';

import Packages from '@common/Packages';
import FeaturedItem from '@common/FeaturedItem';

export type Part = {
  id?: string;
  title: string;
  subtitle?: string;
  description: string;
  items?: string[];
  type: 'PACKAGES' | 'IDEA';
  tip?: string;
  contactButton?: string;
  price?: string;
};

const Services = (): JSX.Element => {
  const align = 'center';

  return (
    <PageWrap>
      <>
        {translate('aboutUs.parts').map((p: Part) => {
          return (
            <Box key={p.title as string} mb={4}>
              <Typography variant="h1" mb={8}>
                {p.title}
              </Typography>
              {p.type === 'PACKAGES' ? (
                <Packages data={p.items as unknown as Part[]} contactButton={p.contactButton} />
              ) : (
                <Box display="flex" flexDirection="column" flexWrap="wrap" alignItems="start" justifyContent="space-between">
                  {(p.items as unknown as Part[]).map((item) => {
                    return <FeaturedItem data={item} key={item.title} />;
                  })}
                </Box>
              )}
              <Typography variant="body1" textAlign={align} mt={2} sx={{ whiteSpace: 'pre-wrap' }}>
                {p.tip}
              </Typography>
            </Box>
          );
        })}
      </>
    </PageWrap>
  );
};

export default Services;
