// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "button{border:none}.content-9O8cI{margin-top:30px;display:flex;gap:80px;text-align:left}.contactForm-EdHiG,.extraInfo-VrfnE{display:flex;flex-direction:column;margin-top:40px}.form-hnYgg{display:flex;flex-direction:column;min-width:600px}.formRow-XVPrE{display:flex}.formRow-XVPrE .formField-hE1Gi:not(:last-child){margin-right:20px}.formField-hE1Gi{margin-top:20px;flex:1;display:flex;flex-direction:column}.sentMessage-VSKhq{display:flex;align-items:center;margin-top:20px;background:#55594a;border-radius:22px;padding:10px 16px;color:#fff}.formFieldInput-CMrj-{border:2px solid rgba(0,0,0,0);padding:10px 16px;border-radius:22px;background:#e8e8e8;resize:none;font-family:\"Poppins\";font-size:15px;transition:.3s ease all}.formFieldInput-CMrj-:disabled{opacity:.6}.formFieldInput-CMrj-.error-d3muI{border:2px solid red}.formFieldInput-CMrj-:focus{border:2px solid #55594a;outline:none}.sendButton-sphxI{padding:10px 16px !important;border-radius:22px !important;margin-top:30px !important;font-family:\"Poppins\";font-size:15px;align-self:start;background:#55594a !important;color:#fff !important}.sendButton-sphxI:disabled{opacity:.5;background:#e8e8e8 !important;color:#666 !important}.sendButton-sphxI:focus-visible{background:#3a3d33 !important}.extras-f-t6V{margin-top:55px}.extraLabel-MuIQK{font-weight:bold}@media(max-width: 1500px){.content-9O8cI{gap:50px}}@media(max-width: 1280px){.content-9O8cI{flex-direction:column;text-align:center}.formRow-XVPrE{flex-direction:column}.formField-hE1Gi{margin:10px 0 !important}.form-hnYgg{min-width:100%}.sentMessageIcon-4eGJY{display:none !important}.sendButton-sphxI{margin-left:10px !important}.extras-f-t6V{margin-top:0;margin-bottom:20px}.extraInfo-VrfnE{margin-top:0}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": "content-9O8cI",
	"contactForm": "contactForm-EdHiG",
	"extraInfo": "extraInfo-VrfnE",
	"form": "form-hnYgg",
	"formRow": "formRow-XVPrE",
	"formField": "formField-hE1Gi",
	"sentMessage": "sentMessage-VSKhq",
	"formFieldInput": "formFieldInput-CMrj-",
	"error": "error-d3muI",
	"sendButton": "sendButton-sphxI",
	"extras": "extras-f-t6V",
	"extraLabel": "extraLabel-MuIQK",
	"sentMessageIcon": "sentMessageIcon-4eGJY"
};
export default ___CSS_LOADER_EXPORT___;
