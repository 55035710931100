import React, { useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import includes from 'lodash.includes';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { ButtonBase, Typography, useMediaQuery } from '@mui/material';
import { Helmet } from 'react-helmet';
import Lightbox from 'react-image-lightbox';

import projects from '../../../assets/projects';

import t from '@localise/translate';

import PageWrap from '@layout/PageWrap';
import FullPageWrap from '@layout/FullPageWrap';
import Gallery from '@common/Gallery';
import ProjectFooter from '@common/ProjectFooter';
import ButtonArrow from '@common/ButtonArrow';
import ToolbarButton from '@common/ToolbarButton';

import { Project } from '@ts/Project';

import styles from './style.scss';

import 'react-image-lightbox/style.css';

export const getSmallSrc = (img: string) => `https://aostudio.sk/images/${img}-small.jpg`;
export const getMidSrc = (img: string) => `https://aostudio.sk/images/${img}-mid.jpg`;
export const getFullSrc = (img: string) => `https://aostudio.sk/images/${img}-large.jpg`;

type ImageProps = {
  src: string;
  alt: string;
  className?: string;
  style?: React.CSSProperties;
  onLoad?: () => void;
};

type SelectedVariants = Record<number, string>;

export const PortfolioImage = ({ src, alt, className, onLoad, style }: ImageProps): JSX.Element => {
  const [isLoaded, setIsLoaded] = useState(false);

  return (
    <img
      className={classNames(className, styles.imageHidden, isLoaded && styles.imageLoaded)}
      alt={alt}
      src={src}
      style={style}
      onLoad={() => {
        setIsLoaded(true);
        onLoad && onLoad();
      }}
      onError={(err) => console.error(err)}
    />
  );
};

const nullHelper = (n: number) => (n < 10 ? `0${n}` : `${n}`);

const buildGallery = (project: Project, variants: SelectedVariants): string[] => {
  const images = [];

  if (project.images.mainImages) {
    for (let i = 1; i <= project.images.mainImages; i++) {
      const imageNumber = nullHelper(i);
      const prefix = 'main-';

      images.push(project.images.gallery + prefix + imageNumber);
    }
  }

  for (let i = 1; i <= project.images.items; i++) {
    const imageNumber = nullHelper(i);

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (variants[imageNumber]) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      images.push(project.images.gallery + imageNumber + variants[imageNumber]);
    } else {
      images.push(project.images.gallery + imageNumber);
    }
  }

  return images;
};

const extractImageName = (imageUrl: string) => {
  const fileName = imageUrl.split('/').pop();
  return (fileName as string).replace(/\.[^/.]+$/, '').replace(/-small|-mid|-large/g, '');
};

const ProjectPage = (): JSX.Element => {
  const match = useRouteMatch();

  // const [selectedImageId, setSelectedImageId] = useState<null | string>(null);

  const [isOpen, setIsOpen] = useState(false);
  const [activeImageId, setActiveImageId] = useState<null | string>(null);
  const [showImages, setShowImages] = useState(false);
  const [selectedVariants, setSelectedVariants] = useState<SelectedVariants>({});

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const project: Project | undefined = projects.find((p) => p.id === (match.params?.id as unknown as string));

  const useMobile = useMediaQuery('(max-width: 1280px)');

  const history = useHistory();

  const images = project ? buildGallery(project, selectedVariants) : [];

  /** Disable scroll when modal is open **/
  useEffect(() => {
    if (isOpen) document.body.style.overflow = 'hidden';
    else document.body.style.overflow = 'auto';
  }, [isOpen]);

  useEffect(() => {
    // Reset variants
    setSelectedVariants({});

    if (project?.id) {
      // Reset images
      setTimeout(() => setShowImages(true), 5);
    }
  }, [project?.id]);

  const activeImage = useMemo(() => {
    if (images) {
      const index = images.findIndex((img) => img === activeImageId);

      const img = images[index];
      // const getSrc = (img: string) => `https://aostudio.sk/images/${img}-mid.jpg`;

      const prevId = images[index - 1];
      const nextId = images[index + 1];
      const prevSrc = prevId && getFullSrc(prevId);
      const nextSrc = nextId && getFullSrc(nextId);

      return { prevId, nextId, prevSrc, nextSrc, src: getFullSrc(img) };
    } else {
      return null;
    }
  }, [isOpen, activeImageId]);

  if (!project) {
    return (
      <PageWrap>
        <Typography variant="subtitle1" textAlign="center" mt={6}>
          {t('project.xNotFound')}
        </Typography>
      </PageWrap>
    );
  }

  const renderParameters = () => {
    if (!project.params) return null;

    return (
      <div className={styles.parameters}>
        {project.params.map((p, index) => (
          <div key={index} className={styles.parameter}>
            <Typography variant="body1">
              <Typography variant="body1" component="span" fontWeight="bold">
                {p.title}
              </Typography>
              : {p.label}
            </Typography>
          </div>
        ))}
      </div>
    );
  };

  const renderText = (text?: string | string[], main?: boolean) => {
    if (!text) return null;

    if (typeof text === 'string') {
      return (
        <Typography variant="body1" className={main ? styles.mainText : styles.text} whiteSpace="pre-wrap">
          {text}
        </Typography>
      );
    }

    return text.map((t, index) => (
      <Typography key={index} variant="body1" className={main ? styles.mainText : styles.text} whiteSpace="pre-wrap">
        {t}
      </Typography>
    ));
  };

  const onSelectVariant = (imageNumbers: (number | string)[], postfix: string | undefined) => {
    setSelectedVariants((old) => {
      const newVariants = { ...old };

      imageNumbers.forEach((n) => {
        if (!postfix) delete newVariants[n as number];
        else newVariants[n as number] = postfix;
      });

      return newVariants;
    });
  };

  const renderVariants = () => {
    if (!project.imagesVariant) return null;

    const showMainPicker = project.imagesVariant.items.some((v) => v.showMainPicker !== false);

    if (!showMainPicker) return null;

    return (
      <div className={styles.variantsWrap}>
        <div className={styles.variants}>
          {project.imagesVariant.text && (
            <Typography variant="body1" className={styles.text} mx={1} mb={1} width="100%" textAlign="center">
              {project.imagesVariant.text}
            </Typography>
          )}
          {project.imagesVariant.items
            .filter((v) => v.showMainPicker !== false)
            .map((variant, index) => {
              return (
                <div className={styles.variant} key={index}>
                  {variant.text && (
                    <Typography variant="body1" mr={2} textAlign="center">
                      {variant.text}
                    </Typography>
                  )}
                  <div className={styles.variantPicker}>
                    {variant.options.map((v) => {
                      const isSelectedVariant = variant.itemNumbers.every((num) => selectedVariants[num] === v.imagePostfix);

                      return (
                        <ButtonBase
                          disableRipple
                          key={v.color || v.shape}
                          classes={{ root: classNames(styles.variantOption, isSelectedVariant && styles.selected) }}
                          onClick={() => {
                            onSelectVariant(variant.itemNumbers, v.imagePostfix as string);
                          }}
                        >
                          <div className={classNames(styles.variantShape, styles[v.shape])} style={{ background: v.color }}></div>
                          {v.text && (
                            <Typography variant="body1" mr={2} textAlign="center">
                              {v.text}
                            </Typography>
                          )}
                        </ButtonBase>
                      );
                    })}
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    );
  };

  const renderImageVariant = (image: string, isDetail?: boolean) => {
    if (!project.imagesVariant) return null;

    let imageNumber: number | string;

    const variant = project.imagesVariant.items.find((o) => {
      imageNumber =
        o.itemNumbers.find((number) => image.endsWith(`-${number}`) || o.options.some((opt) => image.endsWith(`-${number}${opt.imagePostfix}`))) ||
        '';

      return !!imageNumber;
    });

    if (!variant || (isDetail ? variant.showPickerInDetail === false : variant.showPickerOnImages === false)) return null;

    const onSelectOneVariant = (postfix: string) => {
      // If variant with postfix is already selected
      if (image.endsWith(postfix)) return null;

      if (imageNumber) {
        const newPostfix = postfix || undefined;

        if (variant.showPickerOnImages) {
          setSelectedVariants((old) => ({ ...old, [imageNumber.toString()]: newPostfix }));
        } else {
          onSelectVariant(variant.itemNumbers, newPostfix);
        }
      }

      if (isDetail && image) {
        let newImage = postfix ? image + postfix : image;

        if (!postfix) {
          variant.options.every((v) => (newImage = newImage.replace(v.imagePostfix as string, '')));
        }

        setActiveImageId(newImage);
      }
    };

    return (
      <div className={classNames(styles.imageVariant, isDetail && styles.imageVariantDetail)} key={image}>
        <div className={styles.imageVariantPicker}>
          {variant.options.map((v) => (
            <ButtonBase
              disableRipple
              key={v.color || v.shape}
              classes={{ root: styles.imageVariantOption }}
              onClick={(e) => {
                e.stopPropagation();
                onSelectOneVariant(v.imagePostfix as string);

                // Reset focus to allow arrow navigation
                const elements = document.getElementsByClassName('ril-next-button');

                if (elements[0]) {
                  setTimeout(() => (elements[0] as HTMLButtonElement).focus(), 100);
                }
              }}
            >
              <div className={classNames(styles.imageVariantShape, styles[v.shape])} style={{ background: v.color }}></div>
              {v.text && (
                <Typography variant="body1" className={styles.text} mr={2} textAlign="center">
                  {v.text}
                </Typography>
              )}
            </ButtonBase>
          ))}
        </div>
      </div>
    );
  };

  const hasNextProject = projects.findIndex((p) => p.id === project.id) !== projects.length - 1;

  return (
    <FullPageWrap>
      <Helmet>
        <meta property="og:title" content={project.title} />
        <meta property="og:type" content="website" />
        <meta property="og:description" content={project.description} />
        <meta property="og:url" content={`https://aostudio.sk/portfolio/${project.id}`} />
        <meta property="og:image" content={`https://aostudio.sk/previews/${project.images.previewNumber}-l.jpg`} />
      </Helmet>
      <div className={styles.content}>
        <div className={styles.backWrap}>
          <ButtonArrow className={styles.backButton} onClick={() => history.push(`/portfolio#${project.id}`)}>
            {t('project.backToPortfolio')}
          </ButtonArrow>
          {hasNextProject ? (
            <ButtonArrow reverse onClick={() => history.push(`/portfolio/${projects[projects.findIndex((p) => p.id === project.id) + 1].id}`)}>
              {t('project.nextProject')}
            </ButtonArrow>
          ) : (
            <div />
          )}
        </div>
        <Typography variant="h1" textAlign="center" className={styles.title}>
          {project.title}
        </Typography>
        <Typography variant="subtitle1" textAlign="center" className={styles.description}>
          {project.description}
        </Typography>
        <div className={styles.main}>
          {useMobile && renderParameters()}
          {
            <div className={styles.mainImage}>
              {!!project.images.mainImages ? (
                <>
                  {t('project.mainImage') && (
                    <Typography variant="body1" fontWeight="bold">
                      {t('project.mainImage')}
                      <Typography component="span" fontWeight="normal">
                        :
                      </Typography>
                    </Typography>
                  )}
                  <Gallery
                    isReady={showImages}
                    aspectRatio={project.images.mainImageAspectRatio}
                    images={images.filter((i) => includes(i, 'main-'))}
                    offsetY={project.images.mainImageOffset}
                    onOpenImage={(imageId) => {
                      setActiveImageId(imageId);
                      setIsOpen(true);
                    }}
                  />
                </>
              ) : (
                <div className={styles.placeholder}>
                  <div>{project.id}</div>
                </div>
              )}
            </div>
          }
          <div className={styles.mainContent}>
            {!useMobile && renderParameters()}
            {renderText(project.textTop, true)}
          </div>
        </div>
        {renderText(project.textMid)}
        {renderVariants()}
      </div>
      {images && (
        <div className={styles.gallery}>
          {images.slice(project.images.mainImages || 0, images.length).map((image) => (
            <button
              className={styles.galleryItem}
              key={image}
              onClick={() => {
                setActiveImageId(image);
                setIsOpen(true);
              }}
            >
              <PortfolioImage className={styles.galleryItemImageLoading} alt="preview" src={getSmallSrc(image)} />
              {showImages && <PortfolioImage className={styles.galleryItemImage} alt="preview" src={getMidSrc(image)} />}
              {/** <div className={classNames(selectedImageId === image && styles.active)} /> **/}
              {renderImageVariant(image)}
            </button>
          ))}
        </div>
      )}
      <div className={styles.content}>{renderText(project.textDown)}</div>
      <ProjectFooter projectId={project.id} />

      {isOpen && !!activeImage && (
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        <Lightbox
          mainSrc={activeImage.src}
          nextSrc={activeImage.nextSrc}
          prevSrc={activeImage.prevSrc}
          animationOnKeyInput
          onMovePrevRequest={() => setActiveImageId(activeImage.prevId)}
          onMoveNextRequest={() => setActiveImageId(activeImage.nextId)}
          onCloseRequest={() => setIsOpen(false)}
          enableZoom
          wrapperClassName={styles.lightboxWrap}
          imageCaption={renderImageVariant(extractImageName(activeImage.src), true)}
          toolbarButtons={[
            activeImage.prevSrc && (
              <ToolbarButton
                key="previous"
                icon="previous"
                className={styles.fixedIconPrevious}
                onClick={() => setActiveImageId(activeImage.prevId)}
              />
            ),
            activeImage.nextSrc && (
              <ToolbarButton key="next" icon="next" className={styles.fixedIconNext} onClick={() => setActiveImageId(activeImage.nextId)} />
            ),
          ]}
          /* loader={
                    <div className={styles.galleryLoader}>
                      <Loader />
                    </div>
                  } */
        />
      )}
    </FullPageWrap>
  );
};

export default ProjectPage;
