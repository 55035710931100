import React from 'react';

import styles from './style.scss';
import classNames from 'classnames';

type Props = {
  withTitle?: boolean;
  children: any;
};

const FullPageWrap = ({ children, withTitle = true }: Props) => {
  return <div className={classNames(styles.wrap, withTitle && styles.withTitle)}>{children}</div>;
};

export default FullPageWrap;
