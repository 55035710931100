import React from 'react';
import { useHistory } from 'react-router-dom';
import { Box, Typography } from '@mui/material';

import { Part } from '@pages/Services';

import LinkButton from '@common/LinkButton';

import styles from './style.scss';

type Props = {
  data: Part[];
  contactButton?: string;
};

const Packages = ({ data, contactButton }: Props) => {
  const history = useHistory();

  const onContactClick = (type: string) => history.push(`/contact/${encodeURI(type)}`);

  return (
    <div className={styles.wrap}>
      <div className={styles.body}>
        {data.map((item) => {
          return (
            <div className={styles.package} key={item.title}>
              <div className={styles.packageBody}>
                <Typography variant="h6" fontWeight={600}>
                  {item.title}
                </Typography>
                {item.subtitle && (
                  <Typography component="p" variant="caption" fontSize={14}>
                    {item.subtitle}
                  </Typography>
                )}
                <Typography mt={2} variant="h6" fontWeight={600}>
                  {item.description}
                </Typography>
                <div className={styles.textContent}>
                  {item.items?.map((item, index) => {
                    return (
                      <Box display="flex" key={item} mt={0.5}>
                        <Typography component="p" variant="body1" fontSize={14} key={index} className={styles.textItem}>
                          - {item}
                        </Typography>
                      </Box>
                    );
                  })}
                </div>
              </div>
              <Box display="flex" flexDirection="column">
                {item.price && (
                  <Typography mt={2} variant="h6" fontWeight={600}>
                    {item.price}
                  </Typography>
                )}
                {contactButton && (
                  <div className={styles.buttonWrap}>
                    <LinkButton onClick={() => onContactClick(item.title as string)}>{contactButton}</LinkButton>
                  </div>
                )}
              </Box>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Packages;
