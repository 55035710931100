import { useEffect, useState } from 'react';

export const useFonts = (fontNames = ['TypoGrotesk', 'Poppins']) => {
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    Promise.all(fontNames.map((fontName) => document.fonts.load(`16px "${fontName}"`))).then(() => {
      setIsLoaded(true);
    });
  }, [fontNames]);

  return isLoaded;
};

export default { useFonts };
