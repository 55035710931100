import React, { useState } from 'react';
import classNames from 'classnames';
import { Typography } from '@mui/material';

import translate from '@localise/translate';

import PageWrap from '@layout/PageWrap';

import imgAnnette from '@assets/images/annette.jpg';

import styles from './style.scss';

const AboutUs = (): JSX.Element => {
  const align = 'center';

  const [imageLoaded, setImageLoaded] = useState(false);

  return (
    <PageWrap>
      <Typography
        variant="h1"
        textAlign={align}
        mt={0}
        mb={6}
        color="primary"
        fontFamily="TypoGrotesk"
        fontSize={80}
        fontWeight="800"
        textTransform="lowercase"
      >
        {translate('aboutUs.title')}
      </Typography>
      <div className={styles.content}>
        <div className={styles.imageWrap}>
          <img
            src={imgAnnette}
            onLoad={() => setImageLoaded(true)}
            className={classNames(styles.image, imageLoaded && styles.loaded)}
            alt="annette"
          ></img>
        </div>
        <div>
          <Typography variant="h6" fontWeight={600} sx={{ whiteSpace: 'pre-wrap' }}>
            {translate('aboutUs.subTitle')}
          </Typography>
          <Typography variant="body1" mt={2} sx={{ whiteSpace: 'pre-wrap' }}>
            {translate('aboutUs.description')}
          </Typography>
        </div>
      </div>
    </PageWrap>
  );
};

export default AboutUs;
