import React from 'react';

import styles from './style.scss';
import classNames from 'classnames';

type Props = {
  children: JSX.Element | JSX.Element[];
  fullWidth?: boolean;
};

const PageWrap = ({ children, fullWidth }: Props) => {
  return (
    <section className={styles.section} style={{ width: fullWidth ? '100%' : 'initial' }}>
      <div className={classNames(styles.wrap, fullWidth && styles.fullWidth)}>
        <div className={styles.content}>{children}</div>
      </div>
    </section>
  );
};

export default PageWrap;
