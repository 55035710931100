// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "button{border:none}.project-Ic\\+-Q{position:relative;overflow:hidden;width:33.33333%;aspect-ratio:1/1.3}.project-Ic\\+-Q.home-pVpfq{width:100%;min-height:100svh;aspect-ratio:initial}.project-Ic\\+-Q.home-pVpfq .projectInfo-4B4wA{width:100%;align-items:center;text-align:center;left:0}.project-Ic\\+-Q.home-pVpfq .projectTitle-EIxNe{font-size:80px !important;text-align:center}@media(max-width: 1200px){.project-Ic\\+-Q.home-pVpfq .projectTitle-EIxNe{font-size:46px !important}}.imageSmall-TFrah{position:absolute;width:100%;height:100%;object-fit:cover;object-position:center;filter:blur(8px);-webkit-filter:blur(20px);z-index:-1;opacity:0;animation:show-ieSQt .5s .6s forwards}.image-RsaHj{width:100%;height:100%;object-fit:cover;object-position:center;opacity:0;transition:.5s ease all}.image-RsaHj.loaded-0oPNc{opacity:1}.imageWrap-oJgtb{cursor:pointer;position:absolute;width:100%;height:100%}.imageOverlay-GkMew{position:absolute;top:0;left:0;z-index:0;width:100%;height:100%;background:rgba(0,0,0,.3);transition:.5s ease all}.image-RsaHj,.imageSmall-TFrah,.imageOverlay-GkMew{pointer-events:none;user-select:none}.projectInfo-4B4wA{position:absolute;top:50%;left:0;z-index:1;color:#f7f7f7;padding:25px 30px;transform:translateY(-50%);display:flex;flex-direction:column;align-items:start;box-sizing:border-box;text-align:left}@media(max-width: 1280px){.project-Ic\\+-Q{width:50%}.projectInfo-4B4wA{padding:0 10px}}@media(max-width: 720px){.project-Ic\\+-Q{width:100%}}@keyframes show-ieSQt{to{opacity:1}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"project": "project-Ic+-Q",
	"home": "home-pVpfq",
	"projectInfo": "projectInfo-4B4wA",
	"projectTitle": "projectTitle-EIxNe",
	"imageSmall": "imageSmall-TFrah",
	"show": "show-ieSQt",
	"image": "image-RsaHj",
	"loaded": "loaded-0oPNc",
	"imageWrap": "imageWrap-oJgtb",
	"imageOverlay": "imageOverlay-GkMew"
};
export default ___CSS_LOADER_EXPORT___;
