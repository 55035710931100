// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "button{border:none}.projectFooter-XhcHM{display:flex;justify-content:space-between;margin-top:40px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"projectFooter": "projectFooter-XhcHM"
};
export default ___CSS_LOADER_EXPORT___;
