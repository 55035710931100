// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "button{border:none}.wrap-luOdi{position:absolute;top:0;left:0;width:100%;z-index:1;display:flex;justify-content:space-between;align-items:center;box-sizing:border-box;padding:40px;background:rgba(0,0,0,0);border-bottom:none}.logoText-msikr{font-family:\"TypoGrotesk\";padding:20px;font-weight:900;font-size:40px;color:#55594a}.block-iaJsy{display:flex;flex:1}.blockCenter-9HQ-J{justify-content:center}.blockEnd-GxIGU{justify-content:flex-end}.logoWrap-RJMQp{display:flex;align-items:center;position:relative;padding:0 8px}.label-6SdVu{padding:10px 20px;font-family:\"Poppins\";font-size:18px;color:#f7f7f7;font-weight:600;transition:.3s ease all}.label-6SdVu:hover{color:#fff}.label-6SdVu.selected-DtEVC{color:#fff}.secondary-ApGL6{background:rgba(255,255,255,.05);border-bottom:none}.secondary-ApGL6 .label-6SdVu{color:#666}.secondary-ApGL6 .label-6SdVu:hover{color:#444}.secondary-ApGL6 .label-6SdVu.selected-DtEVC{color:#55594a}.secondary-ApGL6 .logoText-msikr{color:#55594a}.navItem-Mt4d0{border-radius:50px !important;transition:.3s ease all;white-space:nowrap}.navItem-Mt4d0 .label-6SdVu:hover{color:#c8ccbe !important}@media(max-width: 720px){.logoText-msikr{padding:20px 10px 20px 25px;font-size:24px}.wrap-luOdi{margin-bottom:32px}}@media(max-width: 630px){.wrap-luOdi{flex-direction:column;align-items:initial;padding:0}.logoWrap-RJMQp{padding:0;flex:1;display:flex}.logoButton-4YQmX{flex:1;padding:20px 0 !important}.logoText-msikr{text-align:center;padding:0}.navigation-gdM5K{display:flex}.navItem-Mt4d0{flex:1}.navItem-Mt4d0 .label-6SdVu{padding:10px 0}}.logoWrap-RJMQp,.navigation-gdM5K{transition:.3s ease all}.transparentFont-J-YVj .logoWrap-RJMQp,.transparentFont-J-YVj .navigation-gdM5K{opacity:0}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "wrap-luOdi",
	"logoText": "logoText-msikr",
	"block": "block-iaJsy",
	"blockCenter": "blockCenter-9HQ-J",
	"blockEnd": "blockEnd-GxIGU",
	"logoWrap": "logoWrap-RJMQp",
	"label": "label-6SdVu",
	"selected": "selected-DtEVC",
	"secondary": "secondary-ApGL6",
	"navItem": "navItem-Mt4d0",
	"logoButton": "logoButton-4YQmX",
	"navigation": "navigation-gdM5K",
	"transparentFont": "transparentFont-J-YVj"
};
export default ___CSS_LOADER_EXPORT___;
