import React, { useEffect } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { grey } from '@mui/material/colors';
import { createTheme } from '@mui/material';
import { BrowserRouter as Router, Route, useLocation, Switch, Redirect } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

import { Env } from '@functions/index';

import Navbar from '@layout/Navbar/Navbar';
import Footer from '@layout/Footer/Footer';

import Home from '@pages/Home';
import Portfolio from '@pages/Portfolio';
import Project from '@pages/Project';
import AboutUs from '@pages/AboutUs';
import Contact from '@pages/Contact';
import Services from '@pages/Services';
import Admin from '@pages/Admin';

import settings from '@assets/settings';

import './style.scss';

const viewKey = 'identifier';

const theme = createTheme({
  palette: {
    primary: {
      main: '#8B9179',
      light: '#616652',
    },
    secondary: grey,
  },
  typography: {
    fontFamily: 'Poppins',
    h1: {
      fontSize: 46,
      textTransform: 'uppercase',
      fontWeight: '600',
      textAlign: 'center',
    },
    h2: {
      fontSize: 40,
      fontWeight: 300,
    },
    h3: {
      fontSize: 30,
      fontWeight: 300,
    },
    h5: {
      fontSize: 20,
      fontWeight: 300,
    },
    h6: {
      fontSize: 16,
      fontWeight: 300,
    },
    subtitle1: {
      fontSize: 22,
      fontWeight: 400,
    },
    subtitle2: {
      fontSize: 16,
      fontWeight: 400,
    },
    body1: {
      fontWeight: 300,
      fontSize: 15,
      lineHeight: 1.8,
    },
    body2: {
      fontWeight: 300,
      fontSize: 13,
    },
  },
});

const usePageViews = () => {
  const location = useLocation();

  const pageView = () => {
    let identifier = localStorage.getItem(viewKey);

    /** When ignoring visits */
    if (location.pathname === '/ignore') {
      localStorage.setItem(viewKey, 'ignore');
      return;
    }

    if (identifier === 'ignore' || window.location.host?.startsWith('localhost')) {
      return;
    }

    if (!identifier) {
      identifier = uuidv4() as string;

      localStorage.setItem(viewKey, identifier);
    }

    const page = location.pathname;

    fetch(`${Env.apiUrl()}/aostudio/visits`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-API-KEY': settings.apiKey,
      },
      body: JSON.stringify({ identifier, page }),
    })
      .then(() => true)
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    pageView();
  }, [location.pathname]);
};

const useScrollRestoration = () => {
  const location = useLocation();

  useEffect(() => {
    window.scroll(0, 0);
  }, [location.pathname]);
};

const Routes = () => {
  const location = useLocation();
  const noLayout = location.pathname.includes('/admin');

  usePageViews();
  useScrollRestoration();

  return (
    <>
      {!noLayout && <Navbar />}
      <Switch>
        <Route exact path={`/`} component={Home} />
        <Route exact path={`/about`} component={AboutUs} />
        <Route exact path={`/services`} component={Services} />
        <Route exact path={`/contact`} component={Contact} />
        <Route exact path={`/contact/:package`} component={Contact} />
        <Route exact path={`/portfolio`} component={Portfolio} />
        <Route exact path={`/portfolio/:id`} component={Project} />

        <Route exact path={`/admin`} component={Admin} />

        <Redirect to="/" />
      </Switch>
      {!noLayout && <Footer />}
    </>
  );
};

export const App = () => (
  <ThemeProvider theme={theme}>
    <Router>
      <Routes />
    </Router>
  </ThemeProvider>
);
