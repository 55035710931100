import { Part } from '@pages/Services';
import { Box, Typography } from '@mui/material';

type Props = {
  data: Part;
};

const FeaturedItem = ({ data }: Props) => {
  return (
    <Box mb={4} width="100%">
      <Typography variant="h6" fontWeight={600}>
        {data.title}
      </Typography>
      {data.subtitle && <Typography>{data.subtitle}</Typography>}
      <Typography whiteSpace="pre-wrap" textAlign="left">
        {data.description}
      </Typography>
      {data.items?.map((item, index) => {
        return (
          <Typography component="p" variant="caption" fontSize={14} key={index}>
            · {item}
          </Typography>
        );
      })}
    </Box>
  );
};

export default FeaturedItem;
