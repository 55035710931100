// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "button{border:none}.wrap-rzBKg{display:flex;min-height:100px;align-items:center;justify-content:center;padding:0 40px;color:#222}.content-Bubge{display:flex;align-items:center;justify-content:space-between;flex-direction:row-reverse;flex:1}.contact-vjIuB{display:flex;flex-direction:column;align-items:flex-end}.title-C\\+VtU{font-size:20px;margin-bottom:12px}.name-0Np\\+T{font-weight:bolder}.icons-LiD8j{display:flex;gap:12px}.iconButton-6Cvye{border-radius:100px !important}.iconButton-6Cvye svg{fill:#1a1a1a !important;transition:.3s ease all !important}.iconButton-6Cvye:hover svg{fill:#c8ccbe !important}@media(max-width: 600px){.wrap-rzBKg{display:flex;flex-direction:column;padding:40px 0}.content-Bubge{flex-direction:column}.contact-vjIuB{text-align:center;align-items:center}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "wrap-rzBKg",
	"content": "content-Bubge",
	"contact": "contact-vjIuB",
	"title": "title-C+VtU",
	"name": "name-0Np+T",
	"icons": "icons-LiD8j",
	"iconButton": "iconButton-6Cvye"
};
export default ___CSS_LOADER_EXPORT___;
