// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "button{border:none}.section-iA89Y{min-height:calc(100svh - 100px)}.wrap-PIw9Y{padding:0 20px;padding-top:168px;display:flex;justify-content:center}.fullWidth-0TF3N .content-JULKQ{flex:1}.content-JULKQ{max-width:1200px;display:flex;flex-direction:column}.grid-UkkP8{display:flex;flex-wrap:wrap;justify-content:space-between;margin-top:60px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"section": "section-iA89Y",
	"wrap": "wrap-PIw9Y",
	"fullWidth": "fullWidth-0TF3N",
	"content": "content-JULKQ",
	"grid": "grid-UkkP8"
};
export default ___CSS_LOADER_EXPORT___;
