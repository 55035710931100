export interface ParsedUserAgent {
  deviceType: string;
  os: string;
  osVersion: string;
  browser: string;
  browserVersion: string;
  app?: string;
  appVersion?: string;
}

const parseUserAgent = (ua: string): ParsedUserAgent | null => {
  const iphoneRegex = /iPhone.*OS (\d+_\d+(_\d+)?)/;
  const androidRegex = /Android (\d+);/;
  const safariRegex = /Version\/(\d+\.\d+)/;
  const chromeRegex = /Chrome\/(\d+\.\d+\.\d+\.\d+)/;
  const instagramRegex = /Instagram (\d+\.\d+\.\d+\.\d+)/;

  let deviceType = '';
  let os = '';
  let osVersion = '';
  let browser = '';
  let browserVersion = '';
  let app = '';
  let appVersion = '';

  if (iphoneRegex.test(ua)) {
    deviceType = 'iPhone';
    os = 'iOS';
    const osMatch = ua.match(iphoneRegex);
    osVersion = osMatch ? osMatch[1].replace(/_/g, '.') : '';
    if (instagramRegex.test(ua)) {
      app = 'Instagram';
      const appMatch = ua.match(instagramRegex);
      appVersion = appMatch ? appMatch[1] : '';
    }
    if (safariRegex.test(ua)) {
      browser = 'Safari';
      const browserMatch = ua.match(safariRegex);
      browserVersion = browserMatch ? browserMatch[1] : '';
    }
  } else if (androidRegex.test(ua)) {
    deviceType = 'Android';
    os = 'Android';
    const osMatch = ua.match(androidRegex);
    osVersion = osMatch ? osMatch[1] : '';
    if (chromeRegex.test(ua)) {
      browser = 'Chrome';
      const browserMatch = ua.match(chromeRegex);
      browserVersion = browserMatch ? browserMatch[1] : '';
    }
    if (instagramRegex.test(ua)) {
      app = 'Instagram';
      const appMatch = ua.match(instagramRegex);
      appVersion = appMatch ? appMatch[1] : '';
    }
  } else {
    return null;
  }

  return {
    deviceType,
    os,
    osVersion,
    browser,
    browserVersion,
    app: app || undefined,
    appVersion: appVersion || undefined,
  };
};

export default { parseUserAgent };
