import g from 'lodash.get';
import { Env } from '../functions';

import settings from '../assets/settings';

import sk from './sk';

const translate = (key: string, properties?: Record<string, unknown>) => {
  try {
    let t = g(sk, key);

    if (properties) {
      Object.keys(properties).map((prop) => {
        t = t.replace('${' + prop + '}', properties[prop]);
      });
    }

    return t;
  } catch (error) {
    if (!Env.isProduction() && settings.debug.translation) {
      throw new Error(`Missing translate "${key}"`);
    }

    return `Missing translate "${key}"`;
  }
};

export default translate;
