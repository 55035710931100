import React from 'react';
import classNames from 'classnames';
import { ButtonBase } from '@mui/material';

import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material';

import styles from './style.scss';

type Props = {
  onClick: () => void;
  children?: React.ReactNode;
  reverse?: boolean;
  className?: string;
};

const ButtonArrow = ({ onClick, children, className, reverse }: Props) => {
  return (
    <ButtonBase classes={{ root: classNames(styles.button, className, reverse && styles.reverse) }} disableRipple onClick={onClick}>
      {!reverse ? <ArrowBackIos className={styles.buttonIcon} /> : <ArrowForwardIos className={styles.buttonIcon} />}
      <span>{children}</span>
    </ButtonBase>
  );
};

export default ButtonArrow;
