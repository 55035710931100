// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "button{border:none}.section-irh3Z{display:flex;min-height:400px}.sectionSecondary-u4jr6{background:#2e2e2e;position:relative;overflow:hidden}.grid-kOclg{margin-top:60px;display:grid;grid-template-columns:calc(500px - 40px) calc(500px - 40px);grid-template-rows:auto;column-gap:80px;row-gap:75px}.fullGrid-3aPE2{display:flex;flex-wrap:wrap}.wrap-s8Xij{display:flex;flex-direction:column}.wrap-s8Xij.withTitle-MNuvn{margin-top:170px}.secondItem-q-EpD{transform:translateY(60px)}@media(max-width: 1000px){.grid-kOclg{grid-template-columns:calc(500px - 40px);align-self:center}}@media(max-width: calc(500px - 40px + 20px)){.grid-kOclg{grid-template-columns:90dvw}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"section": "section-irh3Z",
	"sectionSecondary": "sectionSecondary-u4jr6",
	"grid": "grid-kOclg",
	"fullGrid": "fullGrid-3aPE2",
	"wrap": "wrap-s8Xij",
	"withTitle": "withTitle-MNuvn",
	"secondItem": "secondItem-q-EpD"
};
export default ___CSS_LOADER_EXPORT___;
