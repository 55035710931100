import React from 'react';
import classNames from 'classnames';

import { ArrowBackIos, ArrowForwardIos, Close } from '@mui/icons-material';
import { ButtonBase } from '@mui/material';

import styles from './style.scss';

type Props = {
  onClick: () => void;
  icon: 'back' | 'previous' | 'next' | 'forward' | 'close';
  className?: string;
  withPosition?: boolean;
};

const ToolbarButton = ({ icon, onClick, className, withPosition }: Props) => {
  let renderIcon;
  let positionClass;

  switch (icon) {
    case 'back':
    case 'previous':
      renderIcon = <ArrowBackIos className={styles.moveButtonIcon} style={{ transform: `translateX(4px)` }} />;
      if (withPosition) positionClass = styles.moveButtonBack;
      break;
    case 'forward':
    case 'next':
      renderIcon = <ArrowForwardIos className={styles.moveButtonIcon} style={{ transform: `translateX(1px)` }} />;
      if (withPosition) positionClass = styles.moveButtonNext;
      break;
    case 'close':
      renderIcon = <Close className={styles.moveButtonIcon} />;
      break;
  }

  return (
    <ButtonBase disableRipple onClick={onClick} classes={{ root: classNames(styles.moveButton, positionClass, className) }}>
      {renderIcon}
    </ButtonBase>
  );
};

export default ToolbarButton;
