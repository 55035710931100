import styles from '@pages/Admin/style.scss';
import { KeyboardArrowUp } from '@mui/icons-material';
import React from 'react';

type Props = {
  rank?: number;
};

const Rank = ({ rank }: Props) => {
  if (rank && rank >= 3) {
    return (
      <div className={styles.rank3}>
        <div className={styles.iconGroup}>
          <KeyboardArrowUp className={styles.icon} />
          <KeyboardArrowUp className={styles.icon} />
          <KeyboardArrowUp className={styles.icon} />
        </div>
        RELEVANT
      </div>
    );
  }

  if (rank === 2) {
    return (
      <div className={styles.rank2}>
        <div className={styles.iconGroup}>
          <KeyboardArrowUp className={styles.icon} />
          <KeyboardArrowUp className={styles.icon} />
        </div>
        RELEVANT
      </div>
    );
  }

  if (rank === 1) {
    return (
      <div className={styles.rank1}>
        <div className={styles.iconGroup}>
          <KeyboardArrowUp className={styles.icon} />
        </div>
        RELEVANT
      </div>
    );
  }

  return null;
};

export default Rank;
