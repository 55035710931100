// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "button{border:none}.wrap-zpCXY{display:flex;flex-direction:column}.body-eAYaI{display:grid;grid-template-columns:25% 25% 25% 25%;grid-template-rows:auto;column-gap:0;row-gap:0}.package-1fWYu{display:flex;flex-direction:column;justify-content:space-between;padding:0 20px 20px 20px;flex:1;text-align:left}.packageBody-S\\+LPf{display:flex;flex-direction:column}.icon-oTyGN{font-size:12px !important;margin-top:5px;margin-right:4px}.textItem-S8aNg{text-align:left}.buttonWrap-BWxsX{display:flex;margin-top:20px;color:#55594a}.textContent-c3W0J{display:flex;flex-direction:column}@media(max-width: 1280px){.body-eAYaI{grid-template-columns:50% 50%}.package-1fWYu{text-align:center}.buttonWrap-BWxsX{justify-content:center}.packageBody-S\\+LPf{align-items:center}.textContent-c3W0J{width:300px}}@media(max-width: 600px){.body-eAYaI{grid-template-columns:calc(100vw - 40px)}}@media(max-width: 340px){.textContent-c3W0J{width:100%}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "wrap-zpCXY",
	"body": "body-eAYaI",
	"package": "package-1fWYu",
	"packageBody": "packageBody-S+LPf",
	"icon": "icon-oTyGN",
	"textItem": "textItem-S8aNg",
	"buttonWrap": "buttonWrap-BWxsX",
	"textContent": "textContent-c3W0J"
};
export default ___CSS_LOADER_EXPORT___;
