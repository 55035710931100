// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "button{border:none}.moveButton-zYMiA{background:#fff !important;padding:10px !important;border-radius:100px !important;z-index:1;display:flex;justify-content:center;align-items:center}.moveButtonBack-MAkFp,.moveButtonNext-0Y8xt{position:absolute !important;top:50%;transform:translateY(-50%)}.moveButtonBack-MAkFp{left:0}.moveButtonNext-0Y8xt{right:0}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"moveButton": "moveButton-zYMiA",
	"moveButtonBack": "moveButtonBack-MAkFp",
	"moveButtonNext": "moveButtonNext-0Y8xt"
};
export default ___CSS_LOADER_EXPORT___;
