// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "button{border:none}.wrap-pYULn{display:flex;flex-direction:column}.wrap-pYULn.withTitle-FC7aG{margin-top:170px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "wrap-pYULn",
	"withTitle": "withTitle-FC7aG"
};
export default ___CSS_LOADER_EXPORT___;
