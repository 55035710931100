// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "button{border:none}.table-NE7TS{border-collapse:collapse !important;border-spacing:0 4px !important}.trow-R1XJZ{border-bottom:0 solid rgba(0,0,0,0) !important}.thead-Ny942 .cell-ljd3b{color:#ccc;border:none !important;padding:4px 8px;text-transform:uppercase;font-size:11px;letter-spacing:1px}.thead-Ny942 .cell-ljd3b:first-child{padding-left:12px}.tbody-rQG\\+T .cell-ljd3b{padding:8px;min-height:60px;border-bottom:1px solid rgba(238,238,238,.9333333333)}.clickable-UAsJX .cell-ljd3b:first-child{border-left:4px solid rgba(0,0,0,0);transition:.3s ease all}.clickable-UAsJX .trow-R1XJZ:hover .cell-ljd3b:first-child{border-left-color:#55594a}.clickable-UAsJX .tbody-rQG\\+T .trow-R1XJZ{cursor:pointer}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"table": "table-NE7TS",
	"trow": "trow-R1XJZ",
	"thead": "thead-Ny942",
	"cell": "cell-ljd3b",
	"tbody": "tbody-rQG+T",
	"clickable": "clickable-UAsJX"
};
export default ___CSS_LOADER_EXPORT___;
