import React, { useEffect, useState, MouseEvent } from 'react';
import classNames from 'classnames';
import { withRouter, useHistory } from 'react-router-dom';
import { ButtonBase, useMediaQuery } from '@mui/material';

import t from '@localise/translate';

import { Font } from '../../../hooks';
import { Env } from '@functions/index';

import styles from './style.scss';

const Menu = [
  {
    label: t('nav.home'),
    pathname: '/',
    exact: true,
  },
  {
    label: t('nav.portfolio'),
    pathname: '/portfolio',
  },
  {
    label: t('nav.services'),
    pathname: '/services',
  },
  {
    label: t('nav.aboutUs'),
    pathname: '/about',
  },
  {
    label: t('nav.contact'),
    pathname: '/contact',
  },
];

type Props = {
  location: {
    search: string;
    pathname: string;
  };
};

const Navbar = ({ location }: Props): JSX.Element => {
  const [selectedIndex, setSelectedIndex] = useState(0);

  const history = useHistory();
  const fontLoaded = Font.useFonts();
  const useMobile = useMediaQuery('(max-width: 630px)');

  useEffect(() => {
    const index = Menu.findIndex((item) => (item.exact ? location.pathname === item.pathname : location.pathname.startsWith(item.pathname)));

    if (index > -1) {
      setSelectedIndex(index);
    }
  }, [location.pathname]);

  const redirect = (event: MouseEvent, path: string) => {
    event.preventDefault();
    history.push(path);
  };

  const hasSecondaryStyle = !Env.isHomePage();

  return (
    <nav className={classNames(styles.wrap, hasSecondaryStyle && styles.secondary, !fontLoaded && styles.transparentFont)}>
      <div className={styles.block}>
        {(!Env.isHomePage() || useMobile) && (
          <div className={styles.logoWrap}>
            <ButtonBase disableRipple classes={{ root: styles.logoButton }} onClick={(e) => redirect(e, '/')}>
              <span className={styles.logoText}>ao studio</span>
            </ButtonBase>
            {/**<img src={logo} alt="ao-studio-logo" className={styles.logo} /> **/}
          </div>
        )}
      </div>
      <ul className={styles.navigation}>
        {Menu.filter((item) => item.pathname !== '/').map((item, index) => (
          <ButtonBase disableRipple component="li" classes={{ root: styles.navItem }} key={index} onClick={(e) => redirect(e, item.pathname)}>
            <span className={classNames(styles.label, selectedIndex === index + 1 && styles.selected)}>{item.label}</span>
          </ButtonBase>
        ))}
      </ul>
    </nav>
  );
};

export default withRouter(Navbar);
