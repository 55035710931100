// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "button{border:none}.wrap-I-VPM{position:relative;min-height:100svh;display:flex;justify-content:center;align-items:center;flex-direction:column;background:#616652;color:#f7f7f7;overflow:hidden}.svg-rpOsH{position:absolute;top:50%;left:50%;transform:translate(-50%, -50%);height:100%;max-height:100%;z-index:0}.title-Irnmo{font-size:80px !important;position:relative;z-index:1}.subTitle-9ewTw{font-size:80px !important;font-weight:900 !important;position:relative;z-index:1}@media(max-width: 1200px){.title-Irnmo{font-size:5dvw !important}.subTitle-9ewTw{font-size:5dvw !important}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "wrap-I-VPM",
	"svg": "svg-rpOsH",
	"title": "title-Irnmo",
	"subTitle": "subTitle-9ewTw"
};
export default ___CSS_LOADER_EXPORT___;
