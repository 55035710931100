// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "button{border:none}.imageWrap-Bi-Uv{min-width:350px;max-width:350px;padding:8px 0;align-self:start}.image-3NNM2{width:100%;opacity:0;transition:.5s ease all}.image-3NNM2.loaded-GzZ4f{opacity:1}.content-ASY0y{display:flex;gap:32px;max-width:969px;text-align:left}@media(max-width: 1000px){.content-ASY0y{flex-direction:column}.imageWrap-Bi-Uv{padding:0;align-self:center}}@media(max-width: 630px){.imageWrap-Bi-Uv{min-width:100%;max-width:100%}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"imageWrap": "imageWrap-Bi-Uv",
	"image": "image-3NNM2",
	"loaded": "loaded-GzZ4f",
	"content": "content-ASY0y"
};
export default ___CSS_LOADER_EXPORT___;
