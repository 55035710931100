// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "button{border:none}.button-pRqqj{font-family:\"TypoGrotesk\" !important;font-size:16px !important;color:#666 !important;padding:6px 12px 5px 12px !important}.button-pRqqj:focus-visible{border:1px solid #55594a !important;border-radius:4px !important}.buttonIcon--8h9D{font-size:16px !important;color:#666 !important;margin-right:4px !important}.reverse-XuCGp.button-pRqqj{flex-direction:row-reverse}.reverse-XuCGp .buttonIcon--8h9D{margin-left:4px !important;margin-right:0 !important}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": "button-pRqqj",
	"buttonIcon": "buttonIcon--8h9D",
	"reverse": "reverse-XuCGp"
};
export default ___CSS_LOADER_EXPORT___;
