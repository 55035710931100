import React from 'react';
import { Typography } from '@mui/material';

import t from '@localise/translate';

import aoSvg from '@assets/images/ao.svg';

import styles from './style.scss';

const PortfolioAbout = () => {
  return (
    <div className={styles.wrap}>
      <Typography className={styles.title}>{t('aboutProject.title')}</Typography>
      <Typography className={styles.subTitle}>{t('aboutProject.subTitle')}</Typography>
      <img src={aoSvg} className={styles.svg} alt="ao" />
    </div>
  );
};

export default PortfolioAbout;
