import React from 'react';
import { useHistory } from 'react-router-dom';
import translate from '@localise/translate';

import projects from '@assets/projects';

import styles from './style.scss';
import ButtonArrow from '@common/ButtonArrow';

type Props = {
  projectId: string;
};

const ProjectFooter = ({ projectId }: Props) => {
  const history = useHistory();

  const hasNextProject = projects.findIndex((p) => p.id === projectId) !== projects.length - 1;
  const hasPrevProject = projects.findIndex((p) => p.id === projectId) !== 0;

  return (
    <div className={styles.projectFooter}>
      {hasPrevProject ? (
        <ButtonArrow onClick={() => history.push(`/portfolio/${projects[projects.findIndex((p) => p.id === projectId) - 1].id}`)}>
          {translate('project.previousProject')}
        </ButtonArrow>
      ) : (
        <div />
      )}
      {hasNextProject ? (
        <ButtonArrow reverse onClick={() => history.push(`/portfolio/${projects[projects.findIndex((p) => p.id === projectId) + 1].id}`)}>
          {translate('project.nextProject')}
        </ButtonArrow>
      ) : (
        <div />
      )}
    </div>
  );
};

export default ProjectFooter;
