import React, { FormEvent, useEffect, useState } from 'react';
import { Box, ButtonBase, Typography, useMediaQuery } from '@mui/material';

import classNames from 'classnames';
import flatten from 'lodash.flatten';

import translate from '@localise/translate';

import PageWrap from '@layout/PageWrap';

import { Validate, Env } from '@functions/index';

import styles from './style.scss';

import { CheckCircle } from '@mui/icons-material';
import { useHistory, useRouteMatch } from 'react-router-dom';

type Extra = {
  label: string;
  value: string;
};
type Extras = Extra[][];

type Field = {
  id: string;
  required?: boolean;
  validate?: 'email';
  type?: 'textarea' | 'select';
};

type FieldItem = Field | Field[];

const fields: FieldItem[] = [
  [
    { id: 'name', required: true },
    { id: 'email', required: true, validate: 'email' },
  ],
  [{ id: 'subject' }, { id: 'phone' }],
  { id: 'message', type: 'textarea', required: true },
];

const Contact = (): JSX.Element => {
  const [data, setData] = useState<Record<string, string>>({});
  const [sending, setSending] = useState(false);
  const [sent, setSent] = useState(false);

  const match = useRouteMatch();
  const history = useHistory();
  const useMobile = useMediaQuery('(max-width: 1280px)');

  const emailInvalid = data.email && !Validate.email(data.email.trim());
  const isFormValid = (flatten(fields) as Field[]).filter((r) => r.required).every((r) => data[r.id]) && !emailInvalid;

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const packageId = match.params.package as string;

    if (packageId) {
      setData({
        subject: decodeURI(translate(`contact.prefillSubjectFromAboutUsLink`).replace('$PACKAGE', packageId)),
      });
      history.push('/contact');
    }
  }, []);

  const renderForm = () => {
    const renderField = (field: Field, index: number) => {
      let f;

      const commonProps = {
        onChange: (e: any) => setData({ ...data, [field.id]: e.target.value }),
        id: field.id,
        key: field.id,
        name: field.id,
        value: data[field.id] || '',
        className: classNames(styles.formFieldInput, field.validate === 'email' && emailInvalid && styles.error),
        disabled: sending || sent,
      };

      if (field.type === 'textarea') {
        f = <textarea {...commonProps} rows={5} />;
      } else {
        f = <input {...commonProps} />;
      }

      return (
        <div className={styles.formField} key={index}>
          <div className={styles.formFieldLabel}>
            <Typography variant="body1" fontWeight="bold" textAlign="left">
              {translate(`contact.form.${field.id}`)} {field.required ? '*' : ''}
            </Typography>
          </div>
          {f}
        </div>
      );
    };

    const renderGroup = (group: Field[], index: number) => (
      <div className={styles.formRow} key={index}>
        {group.map(renderField)}
      </div>
    );

    return fields.map((f, index) => (Array.isArray(f) ? renderGroup(f, index) : renderField(f, index)));
  };

  const onSubmit = (e: FormEvent) => {
    e.preventDefault();

    setSending(true);

    fetch(`${Env.apiUrl()}/aostudio/email/send`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-API-KEY': 'aostudio2xAostudio93sL0z',
      },
      body: JSON.stringify(data),
    })
      .then((response) => {
        if (!response.ok) {
          return response.json().then((error) => {
            throw error;
          });
        }
        return response.json();
      })
      .then(() => {
        setSent(true);
      })
      .catch((error) => {
        alert(JSON.stringify(error));
        setSent(false);
        setSending(false);
      });
  };

  return (
    <PageWrap>
      <Typography variant="h1">{translate('contact.title')}</Typography>
      <Box className={styles.content}>
        <section className={styles.contactForm}>
          {useMobile && (
            <Typography variant="h6" component="p" fontWeight="bolder" mb={4}>
              {translate('contact.subTitle')}
            </Typography>
          )}
          <Typography variant="h6" fontWeight="bolder">
            {translate('contact.form.title')}
          </Typography>
          <form className={styles.form} onSubmit={onSubmit}>
            {renderForm()}
            {sent ? (
              <div className={styles.sentMessage}>
                <CheckCircle className={styles.sentMessageIcon} />
                <Typography variant="body2" ml={1}>
                  {translate('contact.form.sendMessageOk')}
                </Typography>
              </div>
            ) : (
              <ButtonBase type="submit" disabled={!isFormValid || sending || sent} classes={{ root: styles.sendButton }}>
                {translate('contact.form.sendMessage')}
              </ButtonBase>
            )}
          </form>
        </section>
        <section className={styles.extraInfo}>
          {!useMobile && (
            <Typography variant="h6" component="p" fontWeight="bolder" textAlign="left">
              {translate('contact.subTitle')}
            </Typography>
          )}
          {(translate('contact.extras') as Extras).map((extra, index) => (
            <div className={styles.extras} key={index}>
              {extra.map((e, i) => (
                <Typography variant="body1" mb={1} key={i}>
                  {e.label && <span className={styles.extraLabel}>{e.label}</span>} {e.value}
                </Typography>
              ))}
            </div>
          ))}
        </section>
      </Box>
    </PageWrap>
  );
};

export default Contact;
