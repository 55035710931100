import React from 'react';

import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';

import styles from './style.scss';
import classNames from 'classnames';

type DataGridColumn<T> = {
  id: string;
  label?: string;
  labelAlignment?: 'left' | 'center' | 'right';
  description?: string;
  width?: number;
  render?: (value: T) => string | number | Node | JSX.Element | null;
};

type Props<T> = {
  data: T[];
  config: DataGridColumn<T>[];
  onRowClick?: (row: T) => void;
};

/*
const getColumns = (config: DataGridColumn[]) => {
  const columns: GridColDef[] = config.map(
    (c) =>
      ({
        ...c,
        field: c.id,
        headerName: c.label || c.id,
        renderCell: c.render ? (record) => c.render(record.row as Record<string, unknown>) : undefined,
      } as GridColDef)
  );

  const calculatedColumns = columns.map((column) => {
    if (column.width) return column;

    const maxWidth = Math.max(
      ...columns.map((row) => String(row[column.field]).length * 22) // You can adjust the multiplier based on your font size and other factors
    );
    return { ...column, width: maxWidth + 10 }; // Adding some padding
  });

  return calculatedColumns;
}; */

const DataGrid = <T,>({ data, config, onRowClick }: Props<T>) => {
  const renderRow = (row: T, column: DataGridColumn<T>) => {
    if (column.render) {
      return column.render(row);
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return row[column.id];
  };

  const onRowClickLocal = (row: T) => {
    if (onRowClick) {
      onRowClick(row);
    }
  };

  return (
    <Table classes={{ root: classNames(styles.table, styles.clickable) }}>
      <TableHead classes={{ root: styles.thead }}>
        <TableRow classes={{ root: styles.trow }}>
          {config.map((c) => (
            <TableCell
              classes={{ root: styles.cell }}
              key={c.id}
              align={c.labelAlignment}
              width={c.id === 'id' || c.id === 'identifier' ? 1 : c.width}
            >
              {c.label || c.id}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody classes={{ root: styles.tbody }}>
        {data.map((d: T, index) => (
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          <TableRow classes={{ root: styles.trow }} key={index.toString() + (d.id || d._id)} onClick={() => onRowClickLocal(d)}>
            {config.map((c) => (
              <TableCell classes={{ root: styles.cell }} key={c.id}>
                {renderRow(d, c)}
              </TableCell>
            ))}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
  /*
  return (
    <MuiDataGrid
      classes={{ root: styles.root, paper: styles.paper }}
      rows={data}
      columns={gridColumns}
      getRowId={(r) => r.id || r._id}
      initialState={{
        pagination: {
          paginationModel: {
            pageSize: 20,
          },
        },
      }}
      pageSizeOptions={[5]}
      // checkboxSelection
      // disableRowSelectionOnClick
    />
  ); */
};

export default DataGrid;
