import React, { useState } from 'react';
import classNames from 'classnames';
import { useHistory, useLocation } from 'react-router-dom';
import Typography from '@mui/material/Typography';

import LinkButton from '@common/LinkButton';

import translate from '@localise/translate';

import { Project } from '@ts/Project';

import styles from './style.scss';

type Props = {
  data: Project;
  className?: string;
};

type ImageParams = {
  small?: boolean;
  large?: boolean;
};

const ProjectPreview = ({ data }: Props) => {
  const [isImageLoaded, setIsImageLoaded] = useState(false);

  const location = useLocation();
  const history = useHistory();

  const getImageUrl = (params: ImageParams = {}) =>
    `https://aostudio.sk/previews/${data.images.previewNumber}${params.small ? '-s' : ''}${params.large ? '-l' : ''}.jpg`;
  const onOpen = () => history.push(`/portfolio/${data.id}`);

  const isHomePage = location.pathname === '/';

  return (
    <div className={classNames(styles.project, isHomePage && styles.home)} id={data.id}>
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
      <div className={styles.imageWrap} onClick={onOpen}>
        <img className={styles.imageSmall} src={getImageUrl({ small: true })} alt={data.id + 'S-Image'} />
        <img
          className={classNames(styles.image, isImageLoaded && styles.loaded)}
          src={getImageUrl({ large: window.innerWidth > 1000 })}
          alt={data.id + 'Image'}
          onLoad={() => setIsImageLoaded(true)}
        />
        <div className={styles.imageOverlay} />
      </div>
      <div className={styles.projectInfo}>
        <div>
          <Typography variant="h1" component="h6" className={styles.projectTitle} align="left">
            {data.previewTitle || data.title}
          </Typography>
          <Typography gutterBottom variant="body1" className={styles.projectSubTitle}>
            {data.previewDescription || data.description}
          </Typography>
        </div>
        {/** <Typography variant="subtitle2">{data.description}</Typography> **/}
        <LinkButton onClick={() => onOpen()}>{translate('portfolio.showMore')}</LinkButton>
      </div>
    </div>
  );
};

export default ProjectPreview;
