import React, { useEffect } from 'react';
import { Typography } from '@mui/material';
import { useLocation } from 'react-router-dom';

import projects from '../../../assets/projects';

import { Env } from '@functions/index';
import translate from '@localise/translate';
import ProjectPreview from '@common/ProjectPreview';
import FullPageWrap from '@layout/FullPageWrap';

import { Project } from '@ts/Project';

import styles from './style.scss';

const Portfolio = (): JSX.Element => {
  const location = useLocation();

  useEffect(() => {
    const hash = window.location.hash?.replace('#', '');

    if (hash) {
      const el = document.getElementById(hash);

      if (el) {
        const y = el.getBoundingClientRect().top + window.scrollY;

        setTimeout(() => window.scrollTo({ top: y - 40, behavior: 'smooth' }), 10);
      }
    }
  }, []);

  const filteredProjects = location.pathname === '/' ? projects.filter((p) => p.visible && p.visibleOnHomepage) : projects.filter((p) => p.visible);

  const grid = filteredProjects.map((p, index) => {
    const className = index % 0 === 1 ? styles.secondItem : undefined;

    return <ProjectPreview key={p.id} data={p as unknown as Project} className={className} />;
  });

  const withTitle = !Env.isHomePage();

  return (
    <FullPageWrap withTitle={withTitle}>
      {withTitle && (
        <>
          <Typography variant="h1" textAlign="center" mt={0}>
            {translate('portfolio.title')}
          </Typography>
          <Typography variant="subtitle1" textAlign="center" mt={2} mb={10}>
            {translate('portfolio.subTitle')}
          </Typography>
        </>
      )}
      <div className={styles.fullGrid}>{grid}</div>
    </FullPageWrap>
  );
};

export default Portfolio;
