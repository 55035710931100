import { useEffect, useState } from 'react';

const useTokenValidation = () => {
  const [loggedIn, setLoggedIn] = useState<boolean | null>(null);

  // Function to handle token change
  const handleTokenChange = () => {
    const user = localStorage.getItem('user-session');

    if (user) {
      try {
        const parsedUser = JSON.parse(user || '');
        setLoggedIn(!!parsedUser.token);
      } catch (err) {
        console.error(err);
      }
    } else {
      setLoggedIn(false);
    }
  };

  useEffect(() => {
    // Initial check when component mounts
    handleTokenChange();

    // Add event listener to detect changes in local storage
    window.addEventListener('storage', handleTokenChange);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('storage', handleTokenChange);
    };
  }, []);

  return loggedIn;
};

export default { useTokenValidation };
