// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "button{border:none}.showMoreButton-DySKm{transform:translateX(-8px);transition:.3s ease all;text-transform:initial !important;font-size:16px !important;font-family:\"TypoGrotesk\" !important;font-weight:bold !important}.showMoreButton-DySKm:hover{color:#c8ccbe !important;background:rgba(0,0,0,0) !important}.showMoreButton-DySKm:focus-visible{border:1px solid #c8ccbe !important;border-radius:4px !important}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"showMoreButton": "showMoreButton-DySKm"
};
export default ___CSS_LOADER_EXPORT___;
