const email = (email: string, level = 'strict') => {
  let re = /.+\\@.+\..+/;

  if (level === 'nonStandardChar') re = /^[A-Za-z0-9@\-_+.]*$/;
  if (level === 'verification') re = /^(.+)@([a-zA-Z0-9-]{1,63}\.)+[a-zA-Z0-9]{2,63}$/;
  if (level === 'strict')
    re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  return re.test(String(email).toLowerCase());
};

export default {
  email,
};
