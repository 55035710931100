import React, { MouseEvent } from 'react';
import { Button } from '@mui/material';
import styles from './style.scss';

type Props = {
  children: string | JSX.Element;
  onClick: (event: MouseEvent) => void;
};

const LinkButton = ({ children, onClick }: Props) => {
  return (
    <Button color="inherit" onClick={onClick} classes={{ root: styles.showMoreButton }} disableRipple>
      {children}
    </Button>
  );
};

export default LinkButton;
